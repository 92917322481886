<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @edit="edit"/>
      </v-col>
    </v-row>
    <base-edit-dialog modal_name="edit_record" ref="edit_record" :page="page" :files_required="true" :multiple_files="true" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseEditDialog,
    BaseTable,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.notes,
    };
  },
  methods: {
    edit(item) {
      this.$refs.edit_record.load(item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>
